import { useContext, useEffect, useState } from "react";
import { Table, Input, Button, Space, Select } from "antd";
import { get } from "../api";
import MyPageHeader from "../components/layout/MyPageHeader";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import { formatDate } from "../helpers";
import debounce from "lodash/debounce";
import AddUserModal from "../components/modals/AddUserModal";

const { Search } = Input;
const { Option } = Select;

const Users = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [planFilter, setPlanFilter] = useState("all");

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    fetchData();
  }, [currentPage, searchText, planFilter]);

  const fetchData = () => {
    get("users/search", {
      pageSize: pagination.pageSize,
      current: currentPage,
      search: searchText,
      plan: planFilter,
    })
      .then((response) => {
        setData(response.data.results);
        setPagination({
          ...pagination,
          current: currentPage,
          total: response.data.totalCount,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    setCurrentPage(pagination.current);
  };

  const columns = [
    {
      title: "Insert date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => {
        return formatDate(date);
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p className="table-main">{text}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <p className="table-main">{text}</p>,
    },
    {
      title: "Plan",
      dataIndex: "plan",
      key: "plan",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "",
      key: "actions",
      render: (text, record) => (
        <>
          <Button onClick={() => navigate("/user/" + record.email)}>
            View
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <MyPageHeader key="ph" title={"Users"} />
      <Button
        style={{ marginTop: 10, marginBottom: 10 }}
        type="primary"
        onClick={() => setIsModalOpened(true)}
      >
        Add new user
      </Button>
      <AddUserModal
        isModalOpen={isModalOpened}
        handleOk={() => {}}
        handleCancel={() => setIsModalOpened(false)}
      ></AddUserModal>
      <Space.Compact block>
        <Search
          placeholder="Search users by email"
          onChange={debounce((e) => {
            setCurrentPage(1);
            setSearchText(e.target.value);
          }, 300)}
        />
        <Select
          style={{ width: 180 }}
          placeholder="Select Plan"
          onChange={(value) => {
            setPlanFilter(value);
            setCurrentPage(1); // Reset page when changing the plan filter
          }}
        >
          <Option value="all">All</Option>
          <Option value="free">Free</Option>
          <Option value="paid">Paid</Option>
          <Option value="inceptly">Free Premium</Option>
        </Select>
      </Space.Compact>
      <Table
        rowKey="id"
        dataSource={data}
        columns={columns}
        pagination={{
          ...pagination,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default Users;
