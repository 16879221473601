import { useEffect, useState } from "react";
import { Input, Card, Col, Row, Popconfirm, message } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { get, del } from "../api";
import MyPageHeader from "../components/layout/MyPageHeader";

const { Meta } = Card;
const { Search } = Input;

const Trainings = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchData();
  }, [searchText]);

  const deleteTraining = (id) => {
    del(`trainings/${id}`)
      .then((response) => {
        fetchData();
        message.success("Click on Yes");
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };

  const fetchData = () => {
    get("trainings", {})
      .then((response) => {
        setData(response.data.trainings);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filteredData = data.filter(
    (item) =>
      !searchText || item.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <MyPageHeader key="ph" title={"Trainings"} />
      <Search
        placeholder="Search trainings"
        onChange={debounce((e) => {
          setSearchText(e.target.value);
        }, 300)}
      />

      <Row gutter={16}>
        {filteredData.map((item) => (
          <Col span={12} key={item.id}>
            <Card
              bordered={false}
              cover={
                item.image ? (
                  <img src={item.image} alt="" />
                ) : (
                  <img src={"/vidTao-placeholder-img.png"} alt="" />
                )
              }
              actions={[
                <Popconfirm
                  title="Delete the training"
                  description="Are you sure to delete this training?"
                  onConfirm={() => deleteTraining(item.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteTwoTone key="delete" twoToneColor="#eb2f96" />
                </Popconfirm>,
              ]}
            >
              <Meta
                title={item.name}
                description={
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Trainings;
