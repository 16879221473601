import moment from "moment";
import "moment/locale/sr";
moment.locale("sr");

export function formatDate(dateString) {
  return moment(dateString).format("D. MMM YYYY.");
}
export function formatDateTime(dateString) {
  return moment(dateString).format("D. MMM YYYY. HH:mm");
}
