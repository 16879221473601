import { Button, Checkbox, Form, Input, Modal, message } from "antd";
import { post } from "../../api";
import { useState } from "react";

function AddUserModal({ isModalOpen, handleOk, handleCancel }) {
  const [isLoading, setIsLoading] = useState(false);
  const onFormSubmit = (values) => {
    setIsLoading(true);
    const params = {
      email: values.email,
      name: values.name,
      planName: values.isPremium ? "inceptly" : "free",
    };
    console.log(params);
    post("users/create", {
      email: values.email,
      name: values.name,
      planName: values.isPremium ? "inceptly" : "free",
    })
      .then((response) => {
        message.success("User successfuly added.");
        setIsLoading(false);
        handleCancel();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      title="Add new user"
      onCancel={handleCancel}
      open={isModalOpen}
      footer={null}
    >
      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        style={{ maxWidth: 500, marginTop: 20 }}
        initialValues={{ remember: true }}
        onFinish={onFormSubmit}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input email!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Name" name="name" rules={[{ required: false }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="isPremium"
          valuePropName="checked"
          wrapperCol={{ offset: 4, span: 16 }}
        >
          <Checkbox>Inceptly premium user?</Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 19, span: 5 }}>
          <Button loading={isLoading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      {/* <div style={{ textAlign: "right" }}>
        <Button key="customButton" type="default" onClick={handleCancel}>
          Close
        </Button>
      </div> */}
    </Modal>
  );
}

export default AddUserModal;
