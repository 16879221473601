import { Layout } from "antd";
import NavRoutes from "../../nav/Routes";
import { Menu } from "antd";
import { AuthContext } from "../../context/AuthContext";
import { useContext, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import Logo from "../../nav/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faHouseUser } from "@fortawesome/free-solid-svg-icons";
import { TabBar } from "antd-mobile";
const { Content, Footer, Sider } = Layout;

function AppContent() {
  const { deviceType } = useContext(GlobalContext);
  const { currentUser } = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  let menuItems = [];
  const tabs = [
    {
      key: "/dashboard",
      title: "Home",
      icon: <FontAwesomeIcon icon={faHouseUser} />,
      // badge: Badge.dot
    },

    {
      key: "/settings",
      title: "Settings",

      icon: <FontAwesomeIcon icon={faCog} />,
    },
  ];
  const setRouteActive = (value) => {
    navigate(value);
  };
  if (currentUser === null) {
    menuItems = [
      {
        label: "Login",
        onClick: () => {
          navigate("/login");
        },
      },
    ];
  } else {
    menuItems = [
      {
        label: "Dashboard",
        onClick: () => {
          navigate("/dashboard");
        },
      },
      {
        label: "Users",
        onClick: () => {
          navigate("/users");
        },
      },
      {
        label: "Find Firebase User",
        onClick: () => {
          navigate("/fb-users");
        },
      },
      {
        label: "Brands",
        onClick: () => {
          navigate("/brands");
        },
      },
      {
        label: "Trainings",
        onClick: () => {
          navigate("/trainings");
        },
      },
      {
        label: "Logout",
        onClick: () => {
          navigate("/logout");
        },
      },
    ];
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        collapsed={collapsed}
        onBreakpoint={() => {
          // console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type, "collapsed");
          setCollapsed(collapsed);
        }}
      >
        <Logo />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          items={menuItems.map((i, index) => ({
            key: String(index + 1),
            label: i.label,
            onClick: () => {
              console.log(deviceType, "dt");
              if (deviceType === "mobile") setCollapsed(true);
              i.onClick();
            },
            children:
              i.children &&
              i.children.map((c, cindex) => ({
                key: index + "-" + String(cindex + 1),
                label: c.label,
                onClick: c.onClick,
              })),
          }))}
        />
      </Sider>
      <Layout>
        <Content className="main-content">
          <NavRoutes />
          <div className="bottom" hidden>
            <TabBar
              // activeKey={pathname}
              onChange={(value) => setRouteActive(value)}
            >
              {tabs.map((item) => (
                <TabBar.Item
                  key={item.key}
                  icon={item.icon}
                  title={item.title}
                />
              ))}
            </TabBar>
          </div>
        </Content>
        <Footer>VidTao Dashboard ©2023</Footer>
      </Layout>
    </Layout>
  );
}

export default AppContent;
