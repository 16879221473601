import { useEffect, useState } from "react";
import { Button, Form, Input, Space, Table } from "antd";
import { formatDateTime } from "../../helpers";
import { get, post } from "../../api";

const UserLogs = ({ userId }) => {
    const [logs, setLogs] = useState(null);

    const fetchLogs = () => {
        if (!userId) return;
        get("users/getLogs/" + userId).then((response) => {
          setLogs(response.data);
          console.log(response, "response");
        });
      };
  useEffect(() => {
    fetchLogs();
  }, [userId]);

  return userId ? (
     <>
      <Form
        name="basic"
        layout="vertical"
        
        onFinish={(values) => {
          console.log(values, "values");
          post("users/addLog", {
            user_id: userId,
            message: values.message
          })
            .then((response) => {
              console.log(response, "response");
              fetchLogs();
            })
            .catch((error) => {
              console.log(error);
            });
        }}
      >
        <Space.Compact block >
        <Form.Item
          name="message"
          rules={[{ required: true, message: "Please input your text!" }]}
        >
          <Input style={{minWidth:400}} placeholder="Enter log message"/>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add to log
          </Button>
        </Form.Item>
        </Space.Compact>
      </Form>
      <Table
        dataSource={logs}
        columns={[
          {
            title: "Date",
            dataIndex: "date",
            key: "name", 
            render: (date) => {
              return formatDateTime(date);
            }
          },
          {
            title: "Message",
            dataIndex: "message",
            key: "age"
          },
          {
            title: "Vidtao Admin",
            dataIndex: "admin_name",
            key: "admin_name"
          }
        ]}
      />
    </>
  ) : (
    <div>No User provided</div>
  );
};

export default UserLogs;
