import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBT1Pf100i5fUrcOo9CzFZv3Yb5-xwq-Og",
  authDomain: "swipetube-3c5c6.firebaseapp.com",
  databaseURL: "https://swipetube-3c5c6.firebaseio.com",
  projectId: "swipetube-3c5c6",
  storageBucket: "swipetube-3c5c6.appspot.com",
  messagingSenderId: "724451360078",
  appId: "1:724451360078:web:f842048f876a6db0e4721f",
  measurementId: "G-FHWW27HX7D",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
