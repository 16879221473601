import { TreeSelect } from "antd";
import { useEffect, useState } from "react";

const CategorySelect = ({
  fieldNames,
  categories,
  selectedCategoryId,
  brand_id,
  updateCategory,
  loading,
}) => {
  const [value, setValue] = useState(selectedCategoryId);
  const onChange = (newValue) => {
    setValue(newValue);
    updateCategory(newValue, brand_id);
  };

  useEffect(() => {
    setValue(selectedCategoryId);
  }, [selectedCategoryId]);
  return (
    <TreeSelect
      loading={loading}
      showSearch
      style={{ width: "100%" }}
      value={value}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      placeholder="Please select"
      allowClear
      treeDefaultExpandAll={false}
      onChange={onChange}
      fieldNames={fieldNames}
      treeNodeFilterProp="name"
      treeData={categories}
    />
  );
};

export default CategorySelect;
