import { useEffect, useState } from "react";
import { Table } from "antd";
import { formatDateTime } from "../../helpers";
import { get } from "../../api";

const UserPayments = ({ subscription_id }) => {
  const [paddleData, setPaddleData] = useState(null);
  useEffect(() => {
    get("users/getPaddleData/",{subscription_id}).then((response) => {
      setPaddleData(response.paddleData);
    });
  }, [subscription_id]);

  return paddleData != null ? (
    <>
    <h2>Payments</h2>
      <Table
        dataSource={paddleData.payments}
        columns={[
          {
            title: "Date",
            dataIndex: "payout_date",
            key: "payout_date",
            render: (date) => {
              return formatDateTime(date);
            }, 
            sorter: (a, b) => a.payout_date - b.payout_date,
          },
          {
            title: "Id",
            dataIndex: "id",
            key: "id"
          },
          {
            title: "Subscription Id",
            dataIndex: "subscription_id",
            key: "sid"
          },
          {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: (amount, r) => {
              return r.currency + " " + amount;
            }
          },
          {
            title: "Paid",
            dataIndex: "is_paid",
            key: "is_paid",
            render: (_, r) => {
              return r.is_paid ? 'Yes':'No';
            }
          },
          {
            title: "One Off Charge",
            dataIndex: "is_one_off_charge",
            key: "is_one_off_charge",
            render: (_, r) => {
              return r.is_one_off_charge ? 'Yes':'No';
            }
          
          },
          {
            title: "Receipt",
            dataIndex: "receipt_url",
            key: "receipt_url",
            render: (_, r) => {
              return r.receipt_url ? <a href={r.receipt_url} rel="noreferrer" target="_blank">View</a> : <></>;
            }
          }
        ]}
      />
      <h2>Events</h2>
      <Table
        dataSource={paddleData.events}
        columns={[
          {
            title: "Date",
            dataIndex: "event_time",
            key: "event_time",
            render: (date) => {
              return formatDateTime(date);
            }
          },
          {
            title: "Id",
            dataIndex: "user_paddle_event_id",
            key: "age"
          },
          {
            title: "Subscription Id",
            dataIndex: "subscription_id",
            key: "sid"
          },
          {
            title: "Event",
            dataIndex: "event_type",
            key: "event_type",
            render: (_, r) => {
              return r.event_data.alert_name 
            }
          },
          {
            title: "Status",
            key: "status", render: (_, r) => {
              return r.event_data.status 
            }
          },
          {
            title: "Attempt number",
            key: "attempt_number",
            render: (_, r) => {
              return r.event_data.attempt_number;
            }
          }
         
        ]}
      />
    </>
  ) : (
    <div>loading...</div>
  );
};

export default UserPayments;
