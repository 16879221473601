import { useEffect, useState } from "react";
import { Button, Tabs, Drawer, Form, Input, Checkbox, message } from "antd";
import { get, post } from "../api";
import MyPageHeader from "../components/layout/MyPageHeader";
import ValueLabel from "../components/layout/ValueLabel";
import { formatDate } from "../helpers";
import { useParams } from "react-router-dom";
import UserPayments from "../components/user/UserPayments";
import UserLogs from "../components/user/UserLogs";
import { PlusOutlined } from "@ant-design/icons";

const UserProfile = () => {
  const { email } = useParams();
  const [data, setData] = useState(null);
  const [openChangeEmail, setOpenChangeEmail] = useState(false);

  const fetchData = () => {
    get("users/byEmail", {
      email: email,
    })
      .then((response) => {
        setData(response);
        console.log(
          "AAAAAAAAAAAAAAAAAAAAA: " +
            JSON.stringify(response.userAuth?.providerData)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [email]);

  const addEmailPasswordAuth = () => {
    post("users/addEmailPassAuth", {
      email,
    })
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showChangeEmail = () => {
    setOpenChangeEmail(true);
  };
  const closeChangeEmail = () => {
    setOpenChangeEmail(false);
  };

  const changeEmail = (values) => {
    post("users/change-email", {
      email,
      ...values,
    })
      .then((response) => {
        if (!response.errorMessage) {
          closeChangeEmail();
          fetchData();
        } else {
          alert(response.errorMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeToInceptly = (id) =>
    post("users/changeToInceptly", {
      uid: id,
    })
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
        console.log(error);
      });

  const resetExpiresDate = (id) =>
    post("users/resetExpiresDate", {
      uid: id,
    })
      .then((response) => {
        fetchData();
        message.success("Expires date successfuly removed!");
      })
      .catch((error) => {
        console.log(error);
      });

  const changeToFree = (id) =>
    post("users/changeToFree", {
      uid: id,
    })
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
        console.log(error);
      });

  const UserProfile = data && (
    <>
      {data.databaseData && (
        <>
          <h2>DB Data</h2>
          <ValueLabel label="User ID">{data.databaseData.user_id}</ValueLabel>
          <ValueLabel label="Email">{data.databaseData.email}</ValueLabel>
          <ValueLabel label="Plan">{data.databaseData.plan}</ValueLabel>
          <ValueLabel label="Role">{data.databaseData.role}</ValueLabel>
          <ValueLabel label="Paddle user ID">
            {data.databaseData.paddle_id}
          </ValueLabel>
          <ValueLabel label="Plan ID">{data.databaseData.plan_id}</ValueLabel>
          <ValueLabel label="Subscription ID">
            {data.databaseData.subscription_id}
          </ValueLabel>
          <ValueLabel label="Created">
            {formatDate(data.databaseData.created_at)}
          </ValueLabel>
          <ValueLabel label="Expires">
            {data.databaseData.expires_at
              ? formatDate(data.databaseData.expires_at)
              : "-"}
          </ValueLabel>
          {data.databaseData.expires_at ? (
            <Button
              onClick={() => resetExpiresDate(data.databaseData.user_id)}
              type="primary"
            >
              Reset expires date
            </Button>
          ) : null}
          <ValueLabel label="Is active">
            {data.databaseData.is_active ? "Yes" : "No"}
          </ValueLabel>
          <ValueLabel label="Access token">
            {data.databaseData.access_token}
          </ValueLabel>
        </>
      )}
      {data.userAuth && (
        <>
          <h2>Firebase Auth</h2>
          <ValueLabel label="Name">{data.userAuth.displayName}</ValueLabel>

          <ValueLabel label="uid">{data.userAuth.uid}</ValueLabel>
          <ValueLabel label="Email">{data.userAuth.email}</ValueLabel>
          <ValueLabel label="Email Verified">
            {data.userAuth.emailVerified ? "Yes" : "No"}
          </ValueLabel>
          <ValueLabel label="Disabled">
            {data.userAuth.disabled ? "Yes" : "No"}
          </ValueLabel>
          <ValueLabel label="Last sign in">
            {formatDate(data.userAuth.metadata?.lastSignInTime)}
          </ValueLabel>
          {data.userAuth.providerData.map((provider) => (
            <ValueLabel label="Provider">{provider.providerId}</ValueLabel>
          ))}
          <ValueLabel label="Role">
            {data.userAuth.customClaims ? data.userAuth.customClaims.role : ""}
          </ValueLabel>
        </>
      )}

      {data.firestoreData && (
        <>
          <h2>VidTao 1.0 database</h2>
          <ValueLabel label="Email">{data.firestoreData.email}</ValueLabel>
          <ValueLabel label="Plan">{data.firestoreData.plan}</ValueLabel>
          <ValueLabel label="Role">{data.firestoreData.role}</ValueLabel>
        </>
      )}

      <div>
        {data.databaseData?.plan === "free" && (
          <Button
            hidden={true}
            onClick={() => changeToInceptly(data.databaseData.user_id)}
          >
            Change to Free Premium
          </Button>
        )}
        {data.databaseData?.plan === "inceptly" && (
          <Button
            hidden={true}
            onClick={() => changeToFree(data.databaseData.user_id)}
          >
            Change to Free
          </Button>
        )}

        {!data.userAuth?.providerData.find(
          (p) => p.providerId === "password"
        ) &&
          data.userAuth?.providerData.find(
            (p) => p.providerId === "google.com"
          ) && (
            <Button hidden={true} onClick={() => addEmailPasswordAuth()}>
              Add Email/Password auth
            </Button>
          )}
        {!data.userAuth?.providerData.find(
          (p) => p.providerId === "google.com"
        ) && (
          <Button
            hidden={true}
            onClick={() => showChangeEmail()}
            icon={<PlusOutlined />}
          >
            Change user's email
          </Button>
        )}
      </div>
    </>
  );

  const tabItems = [
    {
      key: "1",
      label: "User Profile",
      children: UserProfile,
    },
    {
      key: "2",
      label: "Payment Log",
      children: data ? (
        <UserPayments subscription_id={data.databaseData.subscription_id} />
      ) : (
        <></>
      ),
    },
    {
      key: "3",
      label: "User Log",
      children: data ? <UserLogs userId={data.databaseData.user_id} /> : <></>,
    },
  ];

  return (
    <>
      <MyPageHeader key="ph" title={"User Profile"} />

      <Tabs
        defaultActiveKey="1"
        type="card"
        items={tabItems}
        onChange={() => {}}
      />

      <Drawer
        title="Create a new account"
        width={360}
        onClose={closeChangeEmail}
        open={openChangeEmail}
      >
        <Form
          layout="vertical"
          onFinish={changeEmail}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
        >
          <Form.Item
            name="newEmail"
            label="New Email"
            rules={[
              {
                required: true,
                message: "Please enter the new email",
              },
            ]}
          >
            <Input placeholder="Please enter the new email" />
          </Form.Item>
          <Form.Item
            name="override"
            valuePropName="checked"
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Checkbox>Override</Checkbox>
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export default UserProfile;
