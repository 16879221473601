import React, { createContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { post } from "../api";
import { Skeleton } from "antd";

export const AuthContext = createContext({
  currentUser: null,
});

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("user in", user);
        // ensure that user had the correct token
        post("auth/setCustomClaims")
          .then((res) => {
            // get the latest token (force refresh)
            user
              .getIdTokenResult(true)
              .then((idTokenResult) => {
                console.log(idTokenResult, "idTokenResult");
                const role = idTokenResult.claims["role"];
                if (role !== "admin" && role !== "super-admin") {
                  console.log("not admin");
                  setCurrentUser(null);
                  signOut(auth)
                    .catch((error) => {
                      console.log(error);
                    })
                    .finally(() => setLoading(false));
                  alert("You are not an admin");
                  return;
                }

                setCurrentUser({
                  name: user.displayName,
                  email: user.email,
                  role,
                });
                setLoading(false);
              })
              .catch(function (error) {
                // Handle error
                console.error(error, "on auth change error");
                setLoading(false);
              });
          })
          .catch(function (error) {
            // Handle error
            console.error(error, "setCustomClaims error");
            setLoading(false);
          });
      } else {
        // User is signed out
        // ...
        console.log("logged out");

        setCurrentUser(null);
        setLoading(false);
      }
    });
  }, []);

  if (loading) {
    return <Skeleton />;
  }

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
