import { auth } from "../../firebase";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Form, Input, Button, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { AuthContext } from "../../context/AuthContext";

const Login = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const handleLogin = async (values) => {
    setLoading(true);
    signInWithEmailAndPassword(auth, values.email, values.password)
      .then((userCredential) => {
        // Login successful
        navigate("/users", { replace: true });
      })
      .catch((error) => {
        notification.error({
          message: "Login Error",
          description: error.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (currentUser !== null) {
      navigate("/users", { replace: true });
    }
  }, [currentUser, navigate]);

  return (
    <div style={{ maxWidth: "300px", margin: "0 auto" }}>
      <h1>Login</h1>
      <Form onFinish={handleLogin}>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Please enter your email email" },
            { type: "email", message: "Please enter valid email address" },
          ]}
        >
          <Input prefix={<UserOutlined />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please enter your password" }]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Login
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            onClick={(_) => {
              const provider = new GoogleAuthProvider();
              return signInWithPopup(auth, provider)
                .then((user) => {
                  console.log("Google sign-in result:", user);
                  navigate("/users", { replace: true });
                })
                .catch((error) => {
                  console.error("Google sign-in error:", error);
                });
            }}
          >
            Sign in with Google
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
