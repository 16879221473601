import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../components/auth/Login";
import Logout from "../components/auth/Logout";
import PrivateRoute from "./PrivateRoute";

import Dashboard from "../pages/Dashboard";
import UserList from "../pages/Users";
import Brands from "../pages/Brands";
import FindFirebaseUser from "../pages/FindFirebaseUser";
import UserProfile from "../pages/UserProfile";
import Trainings from "../pages/Trainings";

const NavRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route element={<PrivateRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/brands" element={<Brands />} />
        <Route path="/users" element={<UserList />} />
        <Route path="/user/:email" element={<UserProfile />} />
        <Route path="/fb-users" element={<FindFirebaseUser />} />
        <Route path="/trainings" element={<Trainings />} />
      </Route>
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
};

export default NavRoutes;
