import { useContext, useEffect, useState } from "react";
import { Table, Input, Button, message } from "antd";
import { get, post } from "../api";
import MyPageHeader from "../components/layout/MyPageHeader";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import { formatDate } from "../helpers";
import debounce from "lodash/debounce";
import CategorySelect from "../components/layout/CategorySelect";

const { Search } = Input;

const Brands = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const { categories } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    fetchData();
  }, [currentPage, searchText]);

  const updateCategory = (categoryId, brandId) => {
    setUpdateLoading(true);
    post("brands/updateCategory", {
      categoryId: categoryId,
      brandId: brandId,
    })
      .then((response) => {
        setUpdateLoading(false);
        message.success(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchData = () => {
    get("brands", {
      pageSize: pagination.pageSize,
      current: currentPage,
      search: searchText,
    })
      .then((response) => {
        setData(response.data.results);
        setPagination({
          ...pagination,
          current: currentPage,
          total: response.data.totalCount,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    setCurrentPage(pagination.current);
  };

  const columns = [
    {
      title: "Insert date",
      dataIndex: "insert_date",
      key: "insert_date",
      render: (date) => {
        return formatDate(date);
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p className="table-main">{text}</p>,
    },
    {
      title: "",
      key: "actions",
      render: (text, record) => (
        <div style={{ width: 350 }}>
          {" "}
          <CategorySelect
            fieldNames={{
              value: "categoryId",
              label: "name",
              children: "children",
            }}
            selectedCategoryId={record.category_id}
            categories={categories}
            brand_id={record.brand_id}
            updateCategory={updateCategory}
            loading={updateLoading}
          ></CategorySelect>
        </div>
      ),
    },
    {
      title: "",
      key: "actions",
      render: (text, record) => (
        <Button onClick={() => navigate(`/brand/${record.brand_id}`)}>
          View
        </Button>
      ),
    },
  ];

  return (
    <>
      <MyPageHeader key="ph" title={"Brands"} />
      <Search
        placeholder="Search brands"
        onChange={debounce((e) => {
          setCurrentPage(1);
          setSearchText(e.target.value);
        }, 300)}
      />
      <Table
        rowKey="id"
        dataSource={data}
        columns={columns}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </>
  );
};

export default Brands;
