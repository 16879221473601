import React from "react";

const Logo = () => {
  return (
    <a href="/dashboard">
      <img
        style={{
          maxWidth: "80%",
          margin: "0 auto",
          marginTop: 24,
          marginBottom: 24,
          marginLeft: "10%"
        }}
        className="main_logo"
        src="/logo.png"
        alt=""
      />
    </a>
  );
};

export default Logo;
