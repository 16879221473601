import MyPageHeader from "../components/layout/MyPageHeader";

const Dashboard = () => {
  return (
    <>
      <MyPageHeader key="ph" title={"Dashboard"} />
    </>
  );
};

export default Dashboard;
